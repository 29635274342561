import type { BaseRoute } from "@/routes/routes.mjs";

const routes: Array<BaseRoute> = [
  {
    path: "/cms",
    component: () => import("@/feature-cms/Homepage.jsx"),
  },
  {
    path: "/cms/news-manager",
    component: () => import("@/feature-cms/ArticleManager.jsx"),
    async fetchData(params, search): Promise<void> {
      const [{ getArticlePreview, getLatestNews }, { hasCMSAccess }] =
        await Promise.all([
          import("@/feature-news/fetches.mjs"),
          import("@/feature-cms/utils.mjs"),
        ]);
      const isStaging = hasCMSAccess(search);
      const latest = await getLatestNews({ isStaging });
      await Promise.all(
        Object.keys(latest).map((game): Promise<unknown> => {
          const hash = latest[game];
          if (Array.isArray(hash)) {
            return Promise.all(hash.map((h) => getArticlePreview(h)));
          } else if (typeof hash === "string") {
            return getArticlePreview(hash);
          }
          return Promise.resolve();
        }),
      );
    },
  },
];

export default routes;
