import cmsRoutes from "@/feature-cms/routes.mjs";
import routes, { appRoutes } from "@/routes/routes.mjs";

export function setup() {
  for (const i of cmsRoutes) for (const j of [routes, appRoutes]) j.push(i);
}

export function teardown() {
  for (const r of [appRoutes, routes])
    for (let i = 0; i < r.length; i += 1) {
      if (cmsRoutes.every((j) => j !== r[i])) continue;
      r.splice(i, 1);
      break;
    }
}
